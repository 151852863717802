import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"

export default () => (
     
  <Layout>

    <SEO title="Kostnaður"
        description="" 
        image="https://nimiuscms.imgix.net/images/kayak-20190719010928.jpg?w=600&h=400&fit=crop&auto=format&q=75,format" 
        pathname="/pricing/"
        />
    
    <Hero 
         headerText="Enginn Kostnaður" 
         subHeaderText="Vefurinn er ekki rekinn með hagnaðarsjónarmiðum og eru bókanir gerðar beint"
         heroBanner="https://nimiuscms.imgix.net/images/kayak-20190719010928.jpg?w=1600&h=400&fit=crop&auto=format&q=75,format"
         /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2>Engin söluþóknun</h2>
            <p>Markmið okkar er að lækka verð á þjónustu og gera fleirum kleift að ferðast um landið og njóta Íslands. Engin söluþóknun er tekin og fara allar bókanir beint til birgja en eðlileg færslugjöld kortafyrirtækja og umsýslugjöld eiga við óbreytt. </p>

            <h2>Lækkað verð á ferðaþjónustu</h2>
            <p>Við hvetjum fyrirtæki til að lækka verð á þjónustu eftir fremsta megni. Bjóða besta mögulega verð til að hvetja sem flesta til að ferðast innanlands og nýta sér þá þjónustu sem er í boði.</p>
        </div>
      </div>
    </section>

  </Layout>
)
